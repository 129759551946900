<template>
  <div class="popup">
    <div class="popup__bck" @click="closePopup()"></div>
    <div class="popup__block">
      <div class="popup__close" @click="closePopup()"><inline-svg :src="require(`@/assets/images/svg/close-ic.svg`)"></inline-svg></div>
      <div class="popup__title"><span>Transaction information</span></div>
      <div class="transaction">
        <div class="transaction__logo">
          <inline-svg :src="require('@/assets/images/svg/transaction-logo.svg')"></inline-svg>
        </div>
        <img :src="require('@/assets/images/transacation-img.png')" alt="transaction-img">
      </div>
      <div class="popup__btn mt-4 mb-4">
        <button class="btn blue" @click="closePopup()">close</button>
      </div>
    </div>
  </div>
</template>
<script>
import {mapMutations} from "vuex";

export default {
  data(){
    return{

    }
  },
  methods:{
    closePopup(){
      this.changeToggleTransactionPopup(false)
    },
    ...mapMutations({
      changeToggleTransactionPopup:'general/changeToggleTransactionPopup'
    })
  }
}
</script>
<style lang="scss" scoped>
@import "src/assets/styles/styles";
.popup{
  position: fixed;
  height: 100vh;
  width: 100vw;
  left: 0;
  top: 0;
  z-index: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  &__bck{
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: rgba(0,0,0, 0.3);
  }
  &__block{
    position: absolute;
    min-height: 50px;
    max-height: 90vh;
    min-width: 250px;
    max-width: 90vw;
    background-color: $white;
    border-radius: 15px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    overflow: auto;
    @media screen and (max-width: 991px){
      max-height: 90vh;
      max-width: 90px;
    }
  }
  &__title{
    width: 100%;
    font-size: 24px;
    font-family: 'Mont', Arial;
    text-transform: uppercase;
    font-weight: 900;
    color: $grey;
    margin-bottom: 10px;
    padding: 15px;
    span{
      position: relative;
      &:before{
        content: '';
        position: absolute;
        left: calc(100% + 25px);
        top: -15px;
        height: 25px;
        width: 25px;
        border-radius: 50%;
        background-color: $yellow;
      }
    }
  }
  .transaction{
    width: 100%;
    position: relative;
    padding: 10px 5px;
    img{
      max-width: 900px;
    }
    &__logo{
      position: absolute;
      background-color: #f3f3f3;
      left: 30px;
      top: 30px;
      svg{
        height: 75px;
        width: 200px;
      }
    }
  }
  &__text{
    margin-bottom: 30px;
  }
  &__close{
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
  }
}
</style>
